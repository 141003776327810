export const MEDIA_USAGE_TYPE_DEFAULT = 'default'
export const MEDIA_USAGE_TYPE_ARTICLE_HERO = 'article_hero'
export const MEDIA_USAGE_TYPE_ARTICLE_LISTING = 'article_listing'
export const MEDIA_USAGE_TYPE_ARTICLE_BODY_BLOCK = 'article_body_block'
export const MEDIA_USAGE_TYPE_ARTICLE_AB_TEST = 'article_ab_test'
export const MEDIA_USAGE_TYPE_ARTICLE_GALLERY = 'article_gallery'
export const MEDIA_USAGE_TYPE_GALLERY = 'gallery'
export const MEDIA_USAGE_TYPE_AUTHOR = 'author'
export const MEDIA_USAGE_TYPE_INFOBOX = 'infobox'
export const MEDIA_USAGE_TYPE_POLL = 'poll'
export const MEDIA_USAGE_TYPE_POLL_OPTION = 'poll_option'
export const MEDIA_USAGE_TYPE_PRINT_PUBLICATION = 'print_publication'
export const MEDIA_USAGE_TYPE_QUIZ = 'quiz'
export const MEDIA_USAGE_TYPE_QUIZ_ANSWER = 'quiz_answer'
export const MEDIA_USAGE_TYPE_QUIZ_QUESTION = 'quiz_question'
export const MEDIA_USAGE_TYPE_QUIZ_RESULT = 'quiz_result'
export const MEDIA_USAGE_TYPE_QUOTE = 'quote'
export const MEDIA_USAGE_TYPE_TOPIC = 'topic'
export const MEDIA_USAGE_TYPE_CONTENT_BLOCK_ITEM = 'content_block_item'
export const MEDIA_USAGE_TYPE_VIDEO = 'video'
export const MEDIA_USAGE_TYPE_RUBRIC_HERO = 'rubric_hero'
export const MEDIA_USAGE_TYPE_RUBRIC_MINIATURE = 'rubric_miniature'
export const MEDIA_USAGE_TYPE_NATIVE_CAMPAIGN = 'native_campaign'
